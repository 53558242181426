import {
  FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  FinancialTableSingleDateWidgetSection,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import {
  FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG,
  SUPPORTED_FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import {
  KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  KpiTableSingleDateWidgetSection,
  KpiTableSingleDateWidgetState,
} from '@/bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { MEDIA_WIDGET_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/media/config';
import {
  REPORT_BUILDER_WIDGET_TYPES,
  ReportBuilderWidgetTypes,
} from '@/entities/report/reportBuilder';
import {
  AllWidgetTypes,
  COMPARISON_DASHBOARD_SECTION_TYPE,
  ComparisonDashboardSectionTypes,
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  EagleEyeDashboardSectionType,
  OBJECT_DASHBOARD_SECTION_TYPE,
  ObjectDashboardSectionTypes,
  ReportObjectDashboardSection,
} from 'bundles/Shared/entities/dashboard';
import {
  FinancialTableSingleDateWidgetConfigDto,
  FinancialTableSinglePeriodWidgetConfigDto,
  HistoricalReviewTableWidgetConfigDto,
  KpiTableWidgetConfigDto,
} from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { AVERAGE_EFFECTIVE_RENT_CHART_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/averageActualRentChart/config';
import {
  DateWidgetState,
  PeriodTypeWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { DELINQUENCY_AVERAGE_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencyAverage';
import { DELINQUENCY_SNAPSHOT_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencySnapshot/config';
import { DELINQUENCY_TRACKER_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencyTracker';
import { GLOBAL_LEASE_TABLE_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable';
import {
  HISTORICAL_TABLE_WIDGET_CONFIG,
  HistoricalReviewTableWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable';
import {
  KPI_CARD_WIDGET_CONFIG,
  KpiCardWidgetConfig,
  KpiCardWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiCard';
import {
  KPI_TABLE_WIDGET_CONFIG,
  KpiTableWidgetSection,
  KpiTableWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import {
  RECENT_RENTROLL_WIDGET_CONFIG,
  UNIT_MIX_SUMMARY_TABLE_CONFIG,
  UNIT_MIX_WIDGET_CONFIG,
} from 'bundles/Shared/widgets/dashboard/widgets/recentRentroll';
import { TEXT_AREA_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/textArea';
import { UnitTableWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/unitTable';
import { MASTER_UNIT_TABLE_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/unitTable/config';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';
import { XYChartWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/widget';
import { DEFAULT_AMCHART_CONFIG_TEMPLATE } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/amchartDefaults';
import {
  XYChartSingleKpiWidgetSection,
  XYChartWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { XYChartSingleKpiWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/widget';
import { ReadonlyDeep, UnionToIntersection } from 'type-fest';
import { UnknownRecord } from 'type-fest/source/internal';
import { DIVIDER_CONFIG } from './divider';
import { XY_CHART_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/xyChart';
import {
  EAGLE_EYE_XY_CHART_DATE_CONFIG,
  XY_SINGLE_KPI_CHART_WIDGET_CONFIG,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/defaults';

export * from './divider';

export const EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP: Record<
  EagleEyeDashboardSectionType,
  WidgetConfiguration
> = {
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.DIVIDER]: DIVIDER_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD]: KPI_CARD_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: KPI_TABLE_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]:
    KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]:
    XY_SINGLE_KPI_CHART_WIDGET_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.GLOBAL_LEASE_TABLE]:
    GLOBAL_LEASE_TABLE_WIDGET_CONFIG,
};

export const OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP = {
  [OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD]: KPI_CARD_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]:
    HISTORICAL_TABLE_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]:
    DELINQUENCY_TRACKER_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_SNAPSHOT_COLUMN_CHART]:
    DELINQUENCY_SNAPSHOT_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_AVERAGE_COLUMN_CHART]:
    DELINQUENCY_AVERAGE_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.RECENT_RENT_ROLL_TABLE]:
    RECENT_RENTROLL_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_TABLE]: {
    ...RECENT_RENTROLL_WIDGET_CONFIG,
    title: 'Unit Mix',
  },
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_SUMMARY_TABLE]: {
    ...RECENT_RENTROLL_WIDGET_CONFIG,
    title: 'Unit Mix Summary',
  },
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: XY_CHART_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG as WidgetConfiguration,
  [OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART]:
    AVERAGE_EFFECTIVE_RENT_CHART_WIDGET_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE]:
    MASTER_UNIT_TABLE_WIDGET_CONFIG,
} as const satisfies Record<ObjectDashboardSectionTypes, WidgetConfiguration>;

export const COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP = {
  // [COMPARISON_DASHBOARD_SECTION_TYPE.KPI_TABLE]: KPI_TABLE_WIDGET_CONFIG,
  // [COMPARISON_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]:
  //   KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  // [COMPARISON_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]:
  //   XY_SINGLE_KPI_CHART_WIDGET_CONFIG,
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG,
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]:
    FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG,
} as const satisfies Record<
  ComparisonDashboardSectionTypes,
  WidgetConfiguration
>;

export const REPORT_BUILDER_WIDGETS_CONFIG_MAP = {
  [REPORT_BUILDER_WIDGET_TYPES.TEXT_AREA]:
    TEXT_AREA_WIDGET_CONFIG as WidgetConfiguration,
  [REPORT_BUILDER_WIDGET_TYPES.KPI_CARD]: KPI_CARD_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.HISTORICAL_TABLE]:
    HISTORICAL_TABLE_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.UNIT_MIX_TABLE]: UNIT_MIX_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.MEDIA]: MEDIA_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.UNIT_MIX_SUMMARY_TABLE]:
    UNIT_MIX_SUMMARY_TABLE_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.FINANCIAL_TABLE_SINGLE_DATE]:
    FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG as WidgetConfiguration,
  [REPORT_BUILDER_WIDGET_TYPES.DELINQUENCY_TRACKER_TABLE]:
    DELINQUENCY_TRACKER_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.RECENT_RENT_ROLL_TABLE]:
    RECENT_RENTROLL_WIDGET_CONFIG,
  [REPORT_BUILDER_WIDGET_TYPES.XY_CHART]: XY_CHART_WIDGET_CONFIG,
} as const satisfies Record<ReportBuilderWidgetTypes, WidgetConfiguration>;

export const WIDGETS_CONFIG_MAP = {
  ...EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP,
  ...OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP,
  ...COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
} as const satisfies Record<AllWidgetTypes, WidgetConfiguration>;

export type WidgetSectionMap = {
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]: HistoricalReviewTableWidgetSection;
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: XYChartWidgetSection;
  [OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD]: KpiCardWidgetSection;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: KpiTableWidgetSection;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]: KpiTableSingleDateWidgetSection;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]: XYChartSingleKpiWidgetSection;

  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]: FinancialTableSinglePeriodWidgetSection;
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]: FinancialTableSingleDateWidgetSection;
} & Record<string, ReportObjectDashboardSection>;

export type ObjectDashboardWidgetStateMap = {
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]: DateWidgetState;
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]: DateWidgetState;
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: XYChartWidgetState;
  [OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE]: UnitTableWidgetState;
};
export type ComparisonDashboardWidgetStateMap = {
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]: DateWidgetState;
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]: DateWidgetState &
    PeriodTypeWidgetState;
};

export type ComparisonDashboardWidgetStateIntersection = UnionToIntersection<
  ComparisonDashboardWidgetStateMap[keyof ComparisonDashboardWidgetStateMap]
>;
export type ObjectDashboardWidgetStateIntersection = UnionToIntersection<
  ObjectDashboardWidgetStateMap[keyof ObjectDashboardWidgetStateMap]
>;

export type EagleEyeDashboardWidgetStateMap = {
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD]: UnknownRecord;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: KpiTableWidgetState;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]: KpiTableSingleDateWidgetState;
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]: XYChartSingleKpiWidgetState;
};

export type DashboardWidgetStateMap = ObjectDashboardWidgetStateMap &
  EagleEyeDashboardWidgetStateMap &
  ComparisonDashboardWidgetStateMap;

const DEFAULT_AM_CHART_CONFIG = {
  type: 'XYChart',
  refs: [],
} as const;

const DEFAULT_TABLE_VIZ_CONFIG = {
  viz_config: {
    column_groups: [],
    rows: [],
    columns: [],
  },
  viz_type: 'table',
} as const satisfies ReadonlyDeep<{
  viz_config: TableVizConfig;
  viz_type: 'table';
}>;

const DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG = {
  columns: [],
  rows: [],
  default_options: {
    date: {
      period_shift: {},
    },
  },
  ag_grid_config: {
    width: 100,
    columnDefs: [],
  },
  viz_config: {
    column_groups: [],
    rows: [],
    columns: [],
  },
};
const DEFAULT_FINANCIAL_TABLE_SINGLE_PERIOD_CONFIG = {
  columns: [],
  rows: [],
  default_options: {
    period_type: 't3',
    date: {
      period_shift: {},
    },
  },
  ag_grid_config: {
    width: 100,
    columnDefs: [],
  },
  viz_config: {
    column_groups: [],
    rows: [],
    columns: [],
  },
  period_types: SUPPORTED_FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES,
} satisfies FinancialTableSinglePeriodWidgetConfigDto;

const OBJECT_DASHBOARD_DEFAULT_CONFIG_MAP = {
  [OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD]: {
    kpis: [],
  } satisfies KpiCardWidgetConfig,
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]: {
    columns: [],
    rows: [],
    ...DEFAULT_TABLE_VIZ_CONFIG,
  } satisfies HistoricalReviewTableWidgetConfigDto,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_SNAPSHOT_COLUMN_CHART]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_AVERAGE_COLUMN_CHART]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.RECENT_RENT_ROLL_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: {
    kpis: [],
    granularities: ['day', 'week', 'month'],
    default_options: { granularity: 'day', date_from: { period_type: 'day' } },
    am_chart_config: DEFAULT_AMCHART_CONFIG_TEMPLATE,
  } satisfies XYChartWidgetConfig,
  [OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART]: {
    granularities: ['month'],
    default_options: {
      granularity: 'month',
      date_from: { period_type: 'month', period_shift: { months: 12 } },
    },
    am_chart_config: DEFAULT_AM_CHART_CONFIG,
  },
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.UNIT_MIX_SUMMARY_TABLE]: {},
  [OBJECT_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG,
  [OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE]: {},
} as const satisfies Record<ObjectDashboardSectionTypes, UnknownRecord>;

const EAGLE_EYE_DASHBOARD_DEFAULT_CONFIG_MAP = {
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD]: {
    kpis: [],
  } satisfies KpiCardWidgetConfig,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: {
    ...DEFAULT_TABLE_VIZ_CONFIG,
    columns: [],
    default_options: {
      period_type: 'mtd',
      date: {
        period_shift: {
          months: 0,
        },
      },
      grouping_type: 'assets',
    },
    period_types: ['mtd', 'ytd'],
  } satisfies KpiTableWidgetConfigDto,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]: {
    ...DEFAULT_TABLE_VIZ_CONFIG,
    columns: [],
    default_options: {
      grouping_type: 'assets',
      date_from: {
        period_type: 'week',
        period_shift: {
          weeks: -1,
        },
      },
      period_type: 'day_to_day',
    },
  } satisfies KpiTableWidgetConfigDto,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI]:
    EAGLE_EYE_XY_CHART_DATE_CONFIG,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.GLOBAL_LEASE_TABLE]: {
    ag_grid_config: {
      type: 'serial',
    },
  },
} as const satisfies Record<
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
  | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI,
  typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.GLOBAL_LEASE_TABLE | UnknownRecord
>;

const COMPARISON_DASHBOARD_DEFAULT_CONFIG_MAP = {
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_CONFIG satisfies FinancialTableSingleDateWidgetConfigDto,
  [COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD]:
    DEFAULT_FINANCIAL_TABLE_SINGLE_PERIOD_CONFIG,
} as const satisfies Record<
  | typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE
  | typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD,
  | FinancialTableSinglePeriodWidgetConfigDto
  | FinancialTableSingleDateWidgetConfigDto
>;

export const DEFAULT_CONFIG_MAP = {
  ...OBJECT_DASHBOARD_DEFAULT_CONFIG_MAP,
  ...EAGLE_EYE_DASHBOARD_DEFAULT_CONFIG_MAP,
  ...COMPARISON_DASHBOARD_DEFAULT_CONFIG_MAP,
};
