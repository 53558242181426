import React, { useState } from 'react';
import { Button, LinkButton, IconButton, Tumbler, Modal, Input } from 'stories';
import { statuses, getNextStatusAction } from 'lib/fund';
import {
  FUND_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
} from 'lib/permissions';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { IFund } from 'types/Fund';
import DropzoneArea from '../../Shared/components/DropzoneArea';

interface Props extends DialogProps<FormData> {
  fund?: IFund;
}

const FundFormModal = ({ fund, onSubmit, onClose }: Props) => {
  const [status, setStatus] = useState(fund?.aasmState);
  const [fundPhoto, setFundPhoto] = useState(
    fund
      ? {
          smallPictureUrl: fund.smallPictureUrl,
        }
      : undefined,
  );
  const [removePicture, setRemovePicture] = useState(false);
  const currentStatusIndex = statuses.findIndex(({ key }) => key === status);
  const nextStatus = getNextStatusAction(statuses[currentStatusIndex]?.key);
  const [offering, setOffering] = useState(fund?.offering ?? false);

  const { confirm } = useModal();

  const changeStatus = async (aasmState) => {
    const result = await confirm({
      title: 'Changing Fund’s Status',
      subtitle: 'Do you want to change Fund’s status?',
      actions: {
        primaryButton: {
          variant: 'success',
          text: `Yes, ${nextStatus.label}`,
        },
        secondaryButton: {
          variant: 'secondary',
          text: 'Cancel',
        },
      },
    });
    if (result) {
      setStatus(aasmState);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (
      fundPhoto?.smallPictureUrl !== fund?.smallPictureUrl &&
      fundPhoto !== undefined
    ) {
      formData.append('fund[picture]', fundPhoto);
    }

    if (removePicture) {
      formData.append('fund[remove_picture]', removePicture);
    }

    if (fund) {
      formData.append('id', fund?.id);
      formData.append('fund[aasm_state]', status);
      formData.append('fund[offering]', offering);
    }

    onSubmit?.(formData)?.finally(() => onClose());
  };

  return (
    <Modal
      toggle={onClose}
      header={fund ? 'Edit Fund' : 'New Fund'}
      size="700"
      actions={
        <div className="mt-tw-4 flex w-full justify-between">
          <Button onClick={onClose} variant="secondary">
            {' '}
            Cancel{' '}
          </Button>
          <Button variant="success" type="submit" form="fundForm">
            {' '}
            {fund ? 'Save Changes' : 'Create Fund'}{' '}
          </Button>
        </div>
      }
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <form
        id="fundForm"
        className="flex flex-col gap-tw-4"
        onSubmit={handleSubmit}
      >
        <div>
          <label htmlFor="fundName">
            Fund Name <span className="red">*</span>
          </label>
          <Input
            name="fund[name]"
            id="fundName"
            placeholder="Fill Fund name"
            defaultValue={fund?.name}
            className="input-light"
          />
        </div>
        <section style={{ height: 200 }}>
          {fundPhoto?.smallPictureUrl && (
            <div className="asset-photo-preview">
              <IconButton
                variant="primary"
                size="l"
                onClick={() => {
                  setRemovePicture(true);
                  setFundPhoto(undefined);
                }}
                iconName="trash"
              />
              <img src={fundPhoto.smallPictureUrl} alt="Fund cover" />
            </div>
          )}
          {!fundPhoto?.smallPictureUrl && (
            <DropzoneArea
              onChange={(files) => {
                setFundPhoto(files[0]);
                setRemovePicture(false);
              }}
            />
          )}
        </section>
        {currentUserAllowedTo('manage', FUND_PORTAL_PRODUCT_NAME) && fund && (
          <>
            <div className="inline-regular -mb-tw-2">Status</div>
            {currentStatusIndex !== -1 && (
              <h6 style={{ color: statuses[currentStatusIndex].color }}>
                {statuses[currentStatusIndex].label}
                {nextStatus && (
                  <LinkButton
                    className="ml-m"
                    onClick={() => changeStatus(nextStatus.status)}
                  >
                    {nextStatus.label}
                  </LinkButton>
                )}
              </h6>
            )}
            <Tumbler onChange={() => setOffering(!offering)} checked={offering}>
              Offering
            </Tumbler>
          </>
        )}
      </form>
    </Modal>
  );
};

export default FundFormModal;
