import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import { Permitted } from 'bundles/Shared/entities/permissions';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';
import { HistoricalReviewTableWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable';
import {
  KpiCardWidgetConfig,
  KpiCardWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiCard';

import {
  KpiTableSingleDateWidgetConfigDto,
  KpiTableWidgetConfigDto,
} from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';

export enum ReportDashboardStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export enum ReportDashboardType {
  EAGLE_EYE = 'eagle_eye',
  OBJECT = 'object_level',
  COMPARISON_MODE = 'comparison_mode',
}

export type EagleEyeBoard = {
  id: string;
  name: string;
  sections: ReportEagleEyeDashboardSections[];
};

export type ReportEagleEyeDashboard = {
  id: string;
  name: string;
  slug: string;
  assets: ReportDashboardAsset[];
  excludedLegalEntities: ReportDashboardLegalEntity[];
  status: ReportDashboardStatus | `${ReportDashboardStatus}`;
  sections: ReportEagleEyeDashboardSections[];
  boards: EagleEyeBoard[];
  segments: ReportDashboardSegment[];
  description?: string | null;
};

export type ReportObjectDashboard = {
  id: string;
  name: string;
  excludedLegalEntities: ReportDashboardLegalEntity[];
  status: ReportDashboardStatus | `${ReportDashboardStatus}`;
  assets: ReportDashboardAsset[];
  permitted: Permitted;
};

export type ReportDashboardFilterSet = {
  id: string;
  name: string;
  assets: ReportDashboardAsset[];
  segments: ReportDashboardSegment[];
  excludedLegalEntities: ReportDashboardLegalEntity[];
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardAsset = {
  id: number;
  name: string;
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardEagleEyeAsset = {
  id: number;
  name: string;
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardLegalEntity = {
  id: string;
  name: string;
  classification: LeClassification;
};

export type ReportDashboardSegment = Pick<ReportSegment, 'id' | 'title'> & {
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardPeriod = DateString;

export type ReportDashboardSectionPosition = {
  x: number;
  y: number;
  w: number;
  h: number;
  maxH?: number;
  maxW?: number;
  minH?: number;
  minW?: number;
  color?: string | null;
};

export type ReportDashboardSectionPositionLayout =
  ReportDashboardSectionPositionWithId[];

export type ReportDashboardSectionPositionLayouts = Record<
  'lg',
  ReportDashboardSectionPositionLayout
>;

export type ReportDashboardSectionPositionWithId =
  ReportDashboardSectionPosition & {
    i: ReportEagleEyeDashboardSection['id'];
    type: AllWidgetTypes;
  };

export const EAGLE_EYE_DASHBOARD_SECTION_TYPE = {
  DIVIDER: 'divider',
  SCORECARD: 'scorecard',
  KPI_CARD: 'kpi_card',
  KPI_TABLE: 'kpi_table',
  KPI_TABLE_SINGLE_DATE: 'kpi_table_single_date',
  XY_CHART_SINGLE_KPI: 'xy_chart_single_kpi',
  GLOBAL_LEASE_TABLE: 'global_lease_table',
} as const;

export const OBJECT_DASHBOARD_SECTION_TYPE = {
  HISTORICAL_REVIEW_TABLE: 'historical_review_table',
  DELINQUENCY_TRACKER_TABLE: 'delinquency_tracker_table',
  DELINQUENCY_AVERAGE_COLUMN_CHART: 'delinquency_average_column_chart',
  DELINQUENCY_SNAPSHOT_COLUMN_CHART: 'delinquency_snapshot_column_chart',
  RECENT_RENT_ROLL_TABLE: 'recent_rent_roll_table',
  XY_CHART: 'xy_chart',
  AVERAGE_ACTUAL_RENT_CHART: 'average_actual_rent_chart',
  UNIT_MIX_TABLE: 'unit_mix_table',
  UNIT_MIX_SUMMARY_TABLE: 'unit_mix_summary_table',
  KPI_CARD: 'kpi_card',
  FINANCIAL_TABLE_SINGLE_DATE: 'financial_table_single_date',
  MASTER_UNIT_TABLE: 'master_unit_table',
} as const;

export const COMPARISON_DASHBOARD_SECTION_TYPE = {
  // currently unsupported widget sections on FE
  // KPI_TABLE: 'kpi_table',
  // KPI_TABLE_SINGLE_DATE: 'kpi_table_single_date',
  // XY_CHART_SINGLE_KPI: 'xy_chart_single_kpi',
  FINANCIAL_TABLE_SINGLE_DATE: 'financial_table_single_date',
  FINANCIAL_TABLE_SINGLE_PERIOD: 'financial_table_single_period',
} as const;

export const ALL_DASHBOARD_SECTION_TYPES = {
  ...EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  ...OBJECT_DASHBOARD_SECTION_TYPE,
  ...COMPARISON_DASHBOARD_SECTION_TYPE,
} as const;

export type EagleEyeDashboardSectionType =
  (typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE)[keyof typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE];

export type ObjectDashboardSectionTypes =
  (typeof OBJECT_DASHBOARD_SECTION_TYPE)[keyof typeof OBJECT_DASHBOARD_SECTION_TYPE];

export type ComparisonDashboardSectionTypes =
  (typeof COMPARISON_DASHBOARD_SECTION_TYPE)[keyof typeof COMPARISON_DASHBOARD_SECTION_TYPE];

export type AllWidgetTypes =
  | EagleEyeDashboardSectionType
  | ObjectDashboardSectionTypes
  | ComparisonDashboardSectionTypes;

export type ReportObjectDashboardSection = {
  id: string;
  title: string;
  position: ReportDashboardSectionPosition;
  widgetType: ObjectDashboardSectionTypes;
  widgetConfig: Record<string, unknown>;
};
export type ReportComparisonDashboardSection = {
  id: string;
  title: string;
  position: ReportDashboardSectionPosition;
  widgetType: ComparisonDashboardSectionTypes;
  widgetConfig: Record<string, unknown>;
};

export type WidgetDateGranularity = 'day' | 'week' | 'month' | 'year';

export type ReportObjectDashboardSections =
  | HistoricalReviewTableWidgetSection
  | XYChartWidgetSection
  | KpiCardWidgetSection;

export type ReportEagleEyeDashboardSection = {
  id: string;
  title: string;
  sectionId: string;
  position: ReportDashboardSectionPosition;
  sectionType: EagleEyeDashboardSectionType;
  widgetType: EagleEyeDashboardSectionType;
  color: string | null;
};

export type ReportDashboardDividerSection = ReportEagleEyeDashboardSection & {
  title: string;
  description: string;
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.DIVIDER;
  sectionType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.DIVIDER;
};

export type EagleEyeKpiCardWidgetSection = ReportEagleEyeDashboardSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD;
  sectionType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD;
  widgetConfig: KpiCardWidgetConfig;
};
export type EagleEyeKpiTableWidgetSection = ReportEagleEyeDashboardSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
  sectionType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
  widgetConfig: KpiTableWidgetConfigDto;
};
export type EagleEyeKpiTableSingleDateWidgetSection =
  ReportEagleEyeDashboardSection & {
    widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE;
    sectionType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE;
    widgetConfig: KpiTableSingleDateWidgetConfigDto;
  };

export type ReportEagleEyeDashboardSections =
  | ReportDashboardDividerSection
  | EagleEyeKpiTableWidgetSection
  | EagleEyeKpiTableSingleDateWidgetSection
  | EagleEyeKpiCardWidgetSection;

export const isReportDashboardDividerSection = (
  section: ReportEagleEyeDashboardSection,
): section is ReportDashboardDividerSection =>
  section.sectionType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.DIVIDER;

export const isReportDashboardKpiCardSection = (
  section: ReportEagleEyeDashboardSection,
): section is EagleEyeKpiCardWidgetSection =>
  section.widgetType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD;

export type DashboardFilterObject = {
  id: number;
  name: string;
  type: DashboardFilterObjectTypes;
  legalEntities: ReportDashboardLegalEntity[];
  leClassification?: LeClassification[];
};

export type DashboardFilterObjectTypes = 'asset' | 'segment' | 'fund';

export type PeriodShiftType =
  | 'days'
  | 'weeks'
  | 'months'
  | 'quarters'
  | 'years';

export type TrailingPeriodType = {
  type: 'month' | 'quarter' | 'day';
  amount: number;
};

export type PeriodShiftConfig = Record<PeriodShiftType, number>;
