import {
  DEFAULT_DATE_RANGES,
  GRANULARITIES,
  PERIOD_SHIFT_SCHEMA,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  transformPeriodShiftDtoToForm,
  transformPeriodShiftToDefaultDateRange,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import * as yup from 'yup';
import { XyChartSingleKpiWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { AmChartConfig, isChartCategorical } from '../lib';

export const X_AXIS_OPTIONS = ['date', 'asset'] as const;

export const XY_CHART_SINGLE_KPI_CONFIG_SCHEMA = yup.object().shape({
  granularity: yup.string().oneOf(GRANULARITIES).required(),
  periodShiftTo: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  periodShiftFrom: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  defaultDateRange: yup
    .string()
    .oneOf(Object.values(DEFAULT_DATE_RANGES))
    .required(),
  xAxis: yup.string().oneOf(X_AXIS_OPTIONS).required(),
});

export type XYChartSingleKpiWidgetConfigForm = yup.InferType<
  typeof XY_CHART_SINGLE_KPI_CONFIG_SCHEMA
>;

export const transformXYChartSingleKpiWidgetConfigToForm = (
  config: XyChartSingleKpiWidgetConfigDto,
): XYChartSingleKpiWidgetConfigForm => {
  const periodShiftFrom = transformPeriodShiftDtoToForm(
    config.default_options.date_from.period_shift,
  );
  const periodShiftTo = transformPeriodShiftDtoToForm(
    config.default_options.date_to?.period_shift,
  );

  return {
    periodShiftFrom,
    periodShiftTo,
    defaultDateRange: transformPeriodShiftToDefaultDateRange(
      config.default_options.date_from.period_shift,
      config.default_options.date_to?.period_shift,
    ),
    xAxis: isChartCategorical(config.am_chart_config as AmChartConfig)
      ? 'asset'
      : 'date',
    granularity: config.default_options.granularity,
  };
};
export const DEFAULT_XY_CHART_SINGLE_KPI_WIDGET_CONFIG = {
  periodShiftFrom: {
    key: 'months',
    value: 0,
  },
  periodShiftTo: {
    key: 'months',
    value: 0,
  },
  granularity: 'month',
  defaultDateRange: 'none',
} as const satisfies Partial<XYChartSingleKpiWidgetConfigForm>;
