import React from 'react';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { Button, Popover, SearchInput } from '@/stories';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import pluralize from 'pluralize';
import CheckList, { ListOption } from 'stories/Checkbox/CheckList';
import { EagleEyeDashboardWidgetContext } from '../../../widgetsHelpers';
import { XYChartSingleKpiWidgetState } from '../widget';
import {
  buildObjectId,
  GROUPING_TYPE_TO_OBJECT_TYPE,
  idToObjectMapper,
  isAssetId,
  isSegmentId,
  parseObjectId,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import { capitalize } from 'lodash-es';

interface Props {
  context: EagleEyeDashboardWidgetContext;
  state: XYChartSingleKpiWidgetState;
  onStateChange: (state: XYChartSingleKpiWidgetState) => void;
}

export function MixedDropdown({ context, state, onStateChange }: Props) {
  const { assets, segments } = context;
  const contextObjects = [
    ...assets.map((asset) => ({
      ...asset,
      type: 'asset',
      name: asset.name,
    })),
    ...segments.map((segment) => ({
      ...segment,
      type: 'segment',
      name: segment.title,
    })),
  ];
  const displayedObjects = contextObjects.filter(
    (obj) =>
      state.groupingType === 'mixed' ||
      obj.type === GROUPING_TYPE_TO_OBJECT_TYPE[state.groupingType],
  );

  const stateObjects = [
    ...state.assets.map(idToObjectMapper('asset')),
    ...state.segments.map(idToObjectMapper('segment')),
  ];
  const { inputProps, filteredItems } = useItemsFilterByText(
    displayedObjects,
    'name',
  );

  const handleChange = (newObjects: ListOption<string>[]) => {
    onStateChange({
      ...state,
      assets: newObjects
        .filter((obj) => isAssetId(obj.value))
        .map((obj) => parseObjectId(obj.value).id),
      segments: newObjects
        .filter((obj) => isSegmentId(obj.value))
        .map((obj) => parseObjectId(obj.value).id),
    });
  };

  return (
    <Popover
      className="flex max-h-[280px] flex-col gap-tw-2 px-0 py-tw-2"
      offset={DEFAULT_DROPDOWN_OFFSET}
      hiddenArrow
      trigger="click"
      placement={'bottom-start'}
      maxWidth={300}
      template={
        <>
          <SearchInput
            {...inputProps}
            className="mx-tw-2"
            placeholder="Search objects"
          />
          <CheckList
            className="overflow-y-auto px-tw-4 pt-tw-2"
            selectAll
            items={filteredItems.map((item) => ({
              label: item.name,
              value: buildObjectId(item),
            }))}
            onChange={(items) => handleChange(items)}
            value={stateObjects.map((obj) => ({
              label: obj.id,
              value: buildObjectId(obj),
            }))}
          />
        </>
      }
    >
      <Button size="s" iconName="asset" variant="secondary">
        {pluralize(
          state.groupingType === 'mixed'
            ? 'Objects'
            : capitalize(state.groupingType),
          state.assets.length + state.segments.length,
          true,
        )}
      </Button>
    </Popover>
  );
}
