import { DEFAULT_TEMPLATE_SERIES_REF_KEY } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/amchartDefaults';
import { cn } from '@/shared/lib/css/cn';
import { getAmchartNumberFormatForByDisplayOptions } from '@/shared/lib/formatting/charts';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { FlexibleFilterByPeriods, ThinTabGroup } from '@/stories';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import * as am5 from '@amcharts/amcharts5';
import { IRoundedRectangleSettings } from '@amcharts/amcharts5';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import {
  AxisRenderer,
  ColumnSeries,
  DateAxis,
  XYChart,
} from '@amcharts/amcharts5/xy';
import { PIPELINE_CHART_COLOR_SET } from 'bundles/Scoreboard/Pipeline/components/chartUtils';
import SecondaryDropdown from 'bundles/Shared/components/SecondaryDropdown';
import {
  ReportDashboardAsset,
  ReportDashboardSegment,
  WidgetDateGranularity,
} from 'bundles/Shared/entities/dashboard';
import { XyChartSingleKpiWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import {
  DashboardWidgetCard,
  DateRangeWidgetState,
  WidgetStateGranularity,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import {
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import {
  addExportToXySingleKpiChart,
  GROUPING_TYPE_TO_OBJECT_TYPE,
  GroupingType,
  idToObjectMapper,
  isChartCategorical,
  ObjectType,
  TAB_ITEMS,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import { XYChartSingleKpiWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { EagleEyeDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { produce } from 'immer';
import { useAmchart } from 'lib/amcharts/useAmchart';
import { chartDateMapper, getReturnDashboardTheme } from 'lib/amcharts/utils';
import { camelCase, groupBy, intersection, orderBy } from 'lodash-es';
import { useMemo, useRef } from 'react';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import { MixedDropdown } from './ui/MixedDropdown';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { generatePrevPeriods } from '@/stories/FlexibleFilterByPeriods/consts';
import dayjs from 'dayjs';

export type XYChartSingleKpiWidgetState = DateRangeWidgetState & {
  granularity: WidgetDateGranularity;
  assets: ReportDashboardAsset['id'][];
  segments: ReportDashboardSegment['id'][];
  groupingType: GroupingType;
  kpi?: number;
};

export function EagleEyeXYChartWidget({
  data,
  mode,
  widgetSection,
  state,
  onStateChange,
  isFetching,
  context,
}: WidgetProps<XyChartSingleKpiWidgetDto, XYChartSingleKpiWidgetSection> &
  WidgetStateProps<XYChartSingleKpiWidgetState> &
  WidgetContextProps<EagleEyeDashboardWidgetContext>) {
  const isCategorical = isChartCategorical(
    widgetSection.widgetConfig.am_chart_config,
  );
  const selectedKpi = isCategorical
    ? null
    : widgetSection.widgetConfig.kpis.find((kpi) => kpi.key === state.kpi);
  const ref = useRef<{
    exporting: am5plugins_exporting.Exporting;
  }>(null);
  const getObjectList = (
    key: GroupingType,
  ): {
    id: number;
    type: ObjectType;
  }[] => {
    if (key === 'mixed') {
      return [
        ...state.assets.map(idToObjectMapper('asset')),
        ...state.segments.map(idToObjectMapper('segment')),
      ];
    }
    const objectMapper = idToObjectMapper(GROUPING_TYPE_TO_OBJECT_TYPE[key]);
    const contextObjectIds = mapListToIds(context[key] ?? []);
    if (state[key] != null) {
      // filter set object can include extra objects, which are not in the dashboard context
      // TODO: move to slice
      return intersection(state[key], contextObjectIds).map(objectMapper);
    }
    return contextObjectIds.map(
      idToObjectMapper(GROUPING_TYPE_TO_OBJECT_TYPE[key]),
    );
  };
  const items = useMemo(() => {
    const objectList = getObjectList(state.groupingType);
    const mappedItems = data?.data.map(chartDateMapper('dateFrom'));
    const filteredItems = mappedItems?.filter(
      (item) =>
        (selectedKpi == null || item.kpiKey === selectedKpi?.key.toString()) &&
        objectList.some(
          (object) =>
            object.type === item.objectType &&
            object.id === Number(item.objectId),
        ),
    );

    return filteredItems ?? [];
  }, [data, selectedKpi]);

  useAmchart(
    ref,
    (root) => {
      if (context.assets == null || context.segments == null) {
        return;
      }
      const seriesNames = getObjectList(state.groupingType).map((object) =>
        object.type === 'asset'
          ? context.assets.find((item) => item.id === object.id)!.name
          : context.segments.find((item) => item.id === object.id)!.title,
      );

      addExportToXySingleKpiChart({
        state,
        ref,
        widgetTitle: widgetSection.title,
        seriesNames,
        selectedKpi,
        root,
        items,
      });

      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', PIPELINE_CHART_COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      const config = widgetSection.widgetConfig.am_chart_config;

      const dataRef = {
        data: items,
        ...groupBy(items, (item) => camelCase(item.objectName)),
        ...groupBy(orderBy(items, 'kpiValue', 'desc'), (item) =>
          item.kpiKey.toString(),
        ),
        selectedKpi,
      };
      parser
        .parse(
          produce(config, (draft) => {
            draft.refs.unshift(dataRef);
            const templateSeriesRef = draft.refs.find(
              (configRef) => DEFAULT_TEMPLATE_SERIES_REF_KEY in configRef,
            );
            if (templateSeriesRef != null) {
              const [template] = templateSeriesRef.templateSeries;
              templateSeriesRef.templateSeries = seriesNames.map((s) => ({
                ...template,
                settings: {
                  ...template.settings,
                  name: s,
                },
                properties: {
                  ...template.properties,
                  data: `#${camelCase(s)}`,
                },
              }));
            }
          }),
          {
            parent: root.container,
          },
        )
        .then(function (chart: XYChart) {
          // Chart is ready
          if (!isCategorical) {
            (chart.xAxes.getIndex(0) as DateAxis<AxisRenderer>).set(
              'baseInterval',
              {
                timeUnit: state.granularity,
                count: 1,
              },
            );
          }

          chart.series.each((series) => {
            if (series.isType('ColumnSeries')) {
              const columnSeries = series as ColumnSeries;
              const adapters = columnSeries.columns.template._settings
                .adapters as {
                property: string;
                condition: {
                  key: string;
                  value: string;
                };
                value: string;
              }[];
              if (adapters == null || adapters.length === 0) {
                return;
              }
              adapters.forEach((adapter) => {
                columnSeries.columns.template.adapters.add(
                  adapter.property as keyof IRoundedRectangleSettings,
                  (value, target) => {
                    if (
                      target.dataItem?.get(adapter.condition.key) ===
                      adapter.condition.value
                    ) {
                      return am5.color(adapter.value);
                    }
                    return value;
                  },
                );
              });
            }
          });
          root.numberFormatter.setAll({
            numberFormat: getAmchartNumberFormatForByDisplayOptions(
              selectedKpi?.value_display_options ?? null,
              {
                abbreviate: false,
              },
            ),
          });
        });
    },
    [items, isCategorical],
  );

  const handleGroupingTypeChange = (newGroupingType: GroupingType) => {
    onStateChange({
      ...state,
      groupingType: newGroupingType,
      assets:
        newGroupingType === 'mixed' || newGroupingType === 'assets'
          ? mapListToIds(
              widgetSection.defaultOptions.objects?.filter(
                (obj) => obj.type === 'asset',
              ) ?? context.assets,
            )
          : [],
      segments:
        newGroupingType === 'mixed' || newGroupingType === 'segments'
          ? mapListToIds(
              widgetSection.defaultOptions.objects?.filter(
                (obj) => obj.type === 'segment',
              ) ?? context.segments,
            )
          : [],
    });
  };

  return (
    <DashboardWidgetCard className={cn(mode === 'edit' && 'h-[500px]')}>
      <DashboardWidgetCard.Header className="gap-tw-2">
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
        </DashboardWidgetCard.Header.Title>
        <GrowDiv />
        <ThinTabGroup
          selectedItem={state.groupingType}
          onSelectedItemChange={(newItem) => {
            handleGroupingTypeChange(newItem.id);
          }}
          items={TAB_ITEMS.filter(
            (item) =>
              widgetSection.defaultOptions.groupingTypes == null ||
              widgetSection.defaultOptions.groupingTypes.includes(item.id),
          )}
        />
      </DashboardWidgetCard.Header>
      <DashboardWidgetCard.Panel>
        {!isCategorical && (
          <SecondaryDropdown
            maxWidth="max-content"
            items={
              widgetSection.widgetConfig.kpis?.map((kpi) => ({
                label: kpi.label,
                id: kpi.key,
              })) ?? []
            }
            selectedItem={state.kpi}
            onSelectedChange={(key) =>
              onStateChange({
                ...state,
                kpi: key,
              })
            }
          />
        )}
        <MixedDropdown
          context={context}
          state={state}
          onStateChange={onStateChange}
        />
        {!isCategorical && (
          <>
            <WidgetStateGranularity
              state={state}
              onStateChange={onStateChange}
              chartRef={ref}
              granularities={widgetSection.defaultOptions.granularities}
            />
          </>
        )}
        {state.granularity === 'year' ? (
          <FlexibleFilterByPeriods
            filterByPeriodsType="ytd"
            isSingleSelection
            possiblePeriods={generatePrevPeriods()}
            periodItems={[
              {
                type: 'ytd',
                period: formatToDateStringForRequest(state.dateFrom),
              },
            ]}
            onUpdatePeriodItems={(newItems) => {
              onStateChange({
                ...state,
                dateFrom: formatToDateStringForRequest(
                  dayjs(newItems[0].period).startOf('year'),
                ),
                dateTo: formatToDateStringForRequest(
                  dayjs(newItems[0].period).endOf('year'),
                ),
              });
            }}
          />
        ) : (
          <WidgetStateCalendarRangeSelector
            state={state}
            onStateChange={onStateChange}
          />
        )}

        <div className="grow" />
        <ExportChartButton chartRef={ref} />
      </DashboardWidgetCard.Panel>
      {isFetching && <AnimationLoader />}
      <div className="grow" ref={ref} />
    </DashboardWidgetCard>
  );
}
